import { Button } from "@chakra-ui/react"
import { CCLink, NextLinkUrl } from "@components/CCLink"
import React, { ComponentProps, FC } from "react"

type Props = {
	children: React.ReactNode
	href: NextLinkUrl
} & ComponentProps<typeof Button>

export const LinkButton: FC<Props> = ({
	href,
	children,
	...restButtonProps
}) => {
	return (
		<Button {...restButtonProps} href={href} as={CCLink}>
			{children}
		</Button>
	)
}
